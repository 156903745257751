import React, { useContext, useEffect, useState } from "react"

import PropTypes from "prop-types"

import washingMachineLight from "../../assets/images/Icons/washing-machine-light.svg"
import washingMachine from "../../assets/images/Icons/washing-machine.svg"
import { AuthenticationContext } from "../../context"
import mainAxios from "../../utils/axiosConfig"
import Link from "../../utils/link"
import HomeInfo from "../Application/HomeInfo"
import { toastMessage, TYPE_ERROR } from "../UI/Toast"

import "./ChoosePlanComponent.scss"

const productionPlans = [
  {
    description: "",
    id: "pay_as_you_go",
    title: "Pay as you go",
  },
  {
    description: "",
    id: "subscription_1",
    title: "Single",
  },
  {
    description: "",
    id: "subscription_2",
    title: "Couple",
  },
  {
    description: "",
    id: "subscription_3",
    title: "Family",
  },
]

const stagingPlans = [
  {
    description: "",
    id: "pay_as_you_go",
    title: "Pay as you go",
  },
  {
    description: "",
    id: "price_1LmtTSB3zG1W97rdawy6IMX6",
    title: "Single",
  }
]

const ChoosePlanComponent = ({ isInfoOpen, setIsInfoOpen }) => {
  const { setPlan, setPlanList, setError, plans } = useContext(
    AuthenticationContext
  )

  const [selected, setSelected] = useState({
    selectedID: "",
  })
  const [isSelected, setIsSelected] = useState(false)

  const hardCodedPlans = process.env.NODE_ENV === "development" ? stagingPlans : productionPlans

  useEffect(() => {
    mainAxios
      .get("/stripe/plans")
      .then((response) => {
        const { data } = response.data.plans
        setPlanList(data)
      })
      .catch((error) => {
        setError([error])
      })
  }, [])

  const styleObject = {
    description: "choose-plan-component__plans__plan__description",
    plan: "choose-plan-component__plans__plan",
    price: "choose-plan-component__plans__plan__price",
    title: "choose-plan-component__plans__plan__title",
  }

  const styleObjectSelected = {
    description:
      "choose-plan-component__plans__plan__description choose-plan-component__plans__plan__description--selected",
    plan:
      "choose-plan-component__plans__plan choose-plan-component__plans__plan--selected",
    price:
      "choose-plan-component__plans__plan__price choose-plan-component__plans__plan__price--selected",
    title:
      "choose-plan-component__plans__plan__title choose-plan-component__plans__plan__title--selected",
  }

  const handleSelect = (id) => {
    setPlan(id)
    setIsSelected(true)
    setSelected({ selectedID: id })
  }

  const getPlanPrice = (id) => {
    const planSelected = plans.filter((plan) => plan.id === id)
    if (planSelected[0]) {
      const amount = planSelected[0].amount / 100
      return `$${(Math.round(amount * 100) / 100).toFixed(2)}`
    } else return "-"
  }

  const checkIsPlanSelected = () => {
    if (!isSelected) {
      toastMessage("Plan not selected.", TYPE_ERROR)
    }
  }

  return (
    <div className="choose-plan-component">
      <p className="choose-plan-component__title">Washroom Package</p>

      <div className="choose-plan-component__description">
        <p>
          We offer a range of pricing options to fit your needs. Our pay as you
          go option consists of a flat rate of $5 per Kg billed per transaction.
          We also offer three levels of monthly subscriptions tailored for
          individuals or families.
        </p>
      </div>

      <div className="choose-plan-component__description choose-plan-component__description--info">
        <p>
          For more details, see our{" "}
          <button to="/#" onClick={() => setIsInfoOpen(true)}>
            info page
          </button>
        </p>
      </div>

      <div className="choose-plan-component__plans">
        {hardCodedPlans.map((plan, index) => {
          return (
            <div
              id={plan.id}
              className={
                isSelected && selected.selectedID === plan.id
                  ? styleObjectSelected.plan
                  : styleObject.plan
              }
              key={index}
              onClick={() => handleSelect(plan.id)}
            >
              <div
                className={
                  isSelected && selected.selectedID === plan.id
                    ? styleObjectSelected.price
                    : styleObject.price
                }
              >
                <p>{getPlanPrice(plan.id)}</p>
              </div>

              <div className="choose-plan-component__plans__plan__icon">
                <img
                  src={
                    isSelected && selected.selectedID === plan.id
                      ? washingMachineLight
                      : washingMachine
                  }
                />
              </div>

              <p
                className={
                  isSelected && selected.selectedID === plan.id
                    ? styleObjectSelected.title
                    : styleObject.title
                }
              >
                {plan.title}
              </p>
            </div>
          )
        })}
      </div>

      <div className="choose-plan-component__tagline">
        <p>
          All prices are charged monthly. <br />
          Added extras may change the cost
        </p>
      </div>

      <div className="choose-plan-component__buttongroup">
        <div className="choose-plan-component__buttongroup--back">
          <Link to="/register">Back</Link>
        </div>
        <div className="choose-plan-component__buttongroup--next">
          <Link
            to={isSelected ? "/credit-card-payment" : "/choose-plan"}
            onClick={checkIsPlanSelected}
          >
            Next Step
          </Link>
        </div>
      </div>
      <div className="email-link">
        <a href="mailto:hello@wrlaundry.com">hello@wrlaundry.com</a>
      </div>
      {isInfoOpen && <HomeInfo setIsInfoOpen={setIsInfoOpen} />}
    </div>
  )
}

ChoosePlanComponent.propTypes = {
  isInfoOpen: PropTypes.bool.isRequired,
  setIsInfoOpen: PropTypes.func.isRequired,
}

export default ChoosePlanComponent
