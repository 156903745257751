import React, { useState } from "react"

import RegisterLayout from "../components/Layout/RegisterLayout"
import ChoosePlanComponent from "../components/LoginRegister/ChoosePlanComponent"

import "../components/LoginRegister/Authenticate.scss"

const ChoosePlanPage = () => {
  const [isInfoOpen, setIsInfoOpen] = useState(false)

  return (
    <RegisterLayout isFooter={false} isHomeInfo={isInfoOpen}>
      <ChoosePlanComponent
        isInfoOpen={isInfoOpen}
        setIsInfoOpen={setIsInfoOpen}
      />
    </RegisterLayout>
  )
}

export default ChoosePlanPage
